import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import SamplePageTest from 'pages/extra-pages/sample-page-test';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - sample page
//const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));
const Registros = Loadable(lazy(() => import('pages/Registros')));
const RegistrosCola = Loadable(lazy(() => import('pages/RegistrosCola')));
const Usuarios = Loadable(lazy(() => import('pages/Usuarios')));
const ViewRegistro = Loadable(lazy(() => import('pages/ViewRegistro')));
const Sectores = Loadable(lazy(() => import('pages/Sectores')));
const Piscinas = Loadable(lazy(() => import('pages/Piscinas')));
const Empacadoras = Loadable(lazy(() => import('pages/Empacadoras')));
const GrupoEconomico = Loadable(lazy(() => import('pages/GrupoEconomico')));
const ViewGrupo = Loadable(lazy(() => import('pages/ViewGrupo')));
const CreateGroup = Loadable(lazy(() => import('pages/CreateGroup')));
const CreateUser = Loadable(lazy(() => import('pages/CreateUser')));
const CreateEmpacadoras = Loadable(lazy(() => import('pages/CreateEmpacadoras')));
const CreateSectores = Loadable(lazy(() => import('pages/CreateSectores')));
const Dashboard = Loadable(lazy(() => import('pages/Dashboard')));
const Clasificacion = Loadable(lazy(() => import('pages/Clasificacion')));
const ViewEmpacadora = Loadable(lazy(() => import('pages/ViewEmpacadora')));
const ViewSector = Loadable(lazy(() => import('pages/ViewSector')));
const ViewPiscina = Loadable(lazy(() => import('pages/ViewPiscina')));
const CreatePiscinas = Loadable(lazy(() => import('pages/CreatePiscinas')));
const Precios = Loadable(lazy(() => import('pages/Precios')));
const PrecioEntero = Loadable(lazy(() => import('pages/PrecioEntero')));
const PrecioCola = Loadable(lazy(() => import('pages/PrecioCola')));
const ClasificacionPrecios = Loadable(lazy(() => import('pages/ClasificacionPrecios')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/dashboard',
          element: <Dashboard />
        },
        {
          path: 'registros-entero',
          element: <Registros />
        },
        {
          path: 'registros-cola',
          element: <RegistrosCola />
        },
        {
          path: 'registo/:id',
          element: <ViewRegistro />
        },
        {
          path: 'sample-page',
          element: <SamplePageTest />
        },
        {
          path: 'sectores',
          element: <Sectores />
        },
        {
          path: 'piscinas',
          element: <Piscinas />
        },
        { path: '/piscinas/create', element: <CreatePiscinas /> },
        { path: '/piscinas/:id', element: <ViewPiscina /> },
        {
          path: 'empacadoras',
          element: <Empacadoras />
        },
        {
          path: 'grupo-economico',
          element: <GrupoEconomico />
        },
        {
          path: 'usuarios',
          element: <Usuarios />
        },
        {
          path: 'grupo-economico/:id',
          element: <ViewGrupo />
        },
        {
          path: '/grupo-economico/create',
          element: <CreateGroup />
        },
        {
          path: '/usuarios/create',
          element: <CreateUser />
        },
        {
          path: '/empacadoras/create',
          element: <CreateEmpacadoras />
        },
        {
          path: '/empacadoras/:id',
          element: <ViewEmpacadora />
        },
        {
          path: '/sectores/create',
          element: <CreateSectores />
        },
        { path: '/sectores/:id', element: <ViewSector /> },
        {
          path: '/clasificacion',
          element: <Clasificacion />
        },
        {
          path: '/precios',
          element: <Precios />
        },
        {
          path: '/preciosEntero',
          element: <PrecioEntero />
        },
        {
          path: '/preciosCola',
          element: <PrecioCola />
        },
        {
          path: '/preciosClasificacion',
          element: <ClasificacionPrecios />
        }
      ]
    }
  ]
};

export default MainRoutes;
